
import { Vue, Component, Prop } from "vue-property-decorator";
import ProgressTimer from "@/components/task/common/ProgressTimer.vue";
import { SessionState, UnitType } from "@/schemas/Enums";
import clientCache from "@/utils/cacheUtils";
import { mapGetters, mapState } from "vuex";
import LoadingMessageDialog from "@/components/dialog/LoadingMessageDialog.vue";
import { NavigationGuardNext, Route } from "vue-router";
import TipsContainer from "@/components/tips/TipsContainer.vue";
import { ISession } from "@/schemas/ISession";
import { ISetting } from "@/schemas/ISetting";
import { ITip } from "@/schemas/ITip";
import { clearIndividualSession } from "@/utils";

@Component({
  name: "IndividualSessionLayout",
  components: { TipsContainer, LoadingMessageDialog, ProgressTimer },
  computed: {
    ...mapGetters("session", ["getSingleSessionTime"]),
    ...mapState("session", ["session"]),
  },
})
export default class IndividualSessionLayout extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;
  @Prop({ type: Function, required: true })
  endOfTimeHandler!: CallableFunction;
  @Prop({ type: Function, required: true })
  setUpTaskHandler!: CallableFunction;

  isLoading = true;
  isFinished = false;
  isSetUp = false;

  remainingTime = NaN;
  beginning = null;

  waitAllConnectedTriggerId = null;
  trackTriggerId = null;

  canRequest = true;
  wait = 0;

  scope = "single";
  setting: ISetting | undefined;

  created(): void {
    // Fetch usefull data from API
    this.$store.dispatch("session/fetchSession", {
      sessionId: this.sessionId,
      next: (status: SessionState) => {
        this.handleSessionStatus(status);
      },
      handleFetchedSession: (session: ISession) => {
        this.$store.dispatch("setting/fetchSetting", {
          settingId: session.setting_id,
          next: (setting: ISetting) => {
            this.setting = setting;
          },
        });
      },
    });
  }

  beforeDestroy(): void {
    this.clearInterval();
    // this.disconnectFromSession({
    //   sessionId: this.sessionId,
    //   channel: this.scope,
    // });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.clearInterval();
    // this.disconnectFromSession({
    //   sessionId: this.sessionId,
    //   channel: this.scope,
    // });
    next();
  }

  clearInterval(): void {
    if (this.trackTriggerId) {
      clearInterval(this.trackTriggerId);
    }
  }

  handleSessionStatus(status: SessionState): void {
    console.log("kjhqsdkfjhqskdjfhkjsdhf")
    if (status === SessionState.Created) {
      this.$store.dispatch("session/connectToSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.waitAllConnectedTriggerId = this.allConnectedHTTPTrigger();

      clearIndividualSession(); // <-- Added by Tim to fix cache issue, when participation multiple sequence with different theme
    }
    console.log(status)
    if(status === SessionState.TipsGroup){
      this.$router.push({
        name: "tipsOverview",
        props: {
          sessionId: this.sessionId,
        },
      });
      return;
    }
    if (status === SessionState.RunningSingle) {
      this.$store.dispatch("session/connectToSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.waitAllConnectedTriggerId = this.allConnectedHTTPTrigger();
      this.setUpTaskHandler();
      this.beginning = new Date(Date.now());
      if (clientCache.get(`${this.sessionId}:has_submitted_single`)) {
        console.log("NOOOOOOOON")
        this.$router.push({
          name: "transitionSession",
          props: {
            sessionId: this.sessionId,
          },
        });
        //     this.$router.push({
        //   name: "groupSession",
        //   params: {
        //     sessionId: this.sessionId,
        //   },
        // });
        return;
      }
    }
    if (status === SessionState.RunningGroup) {
      this.$store.dispatch("session/disconnectFromSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.$router.push({
        name: "groupSession",
        props: {
          sessionId: this.sessionId,
        },
      });
    }
    if (status === SessionState.Finished) {
      this.displayEnd = true;
      console.log("single session is finished");
    }
  }

  get estimatedEnd(): number {
    if (this.session.estimated_individual_end_time) {
      return new Date(this.session.estimated_individual_end_time)
        .getTime()
        .toString();
    } else {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.beginning ??= new Date(Date.now());
      return this.beginning
        .setMinutes(
          this.beginning.getMinutes() +
            this.session.setting?.single_session_time
        )
        .toString();
    }
  }

  get displayTips(): boolean {
    return !!this.setting?.indiv_tips_id;
  }
  get tips(): ITip[] {
    if (this.displayTips) {
      return this.setting?.indiv_tips?.tips;
    } else {
      return [];
    }
  }
  get isCarousel(): {
    carousel_tips: boolean;
    carousel_tip_unit?: UnitType;
    carousel_display_time?: number;
    carousel_occurrence?: number;
  } {
    return this.setting
      ? {
          carousel_tips: this.setting?.carousel_tips,
          carousel_tip_unit: this.setting?.carousel_tip_unit,
          carousel_display_time: this.setting?.carousel_display_time || 1,
          carousel_occurrence: this.setting?.carousel_occurrence || 1,
        }
      : { carousel_tips: false };
  }

  allConnectedHTTPTrigger(timeout: number): number {
    return window.setInterval(
      () => {
        if (this.canRequest || this.wait === 10) {
          this.wait = 0;
          this.canRequest = false;
          this.$store.dispatch("session/getSessionStatus", {
            sessionId: this.sessionId,
            next: (response) => {
              this.canRequest = true;
              this.trackHandler(response);
            },
            channel: this.scope,
          });
        } else {
          this.wait++;
        }
      },
      timeout ? timeout : 1500
    );
  }

  trackHandler(message: {
    status: SessionState;
    remaining_time?: number;
  }): void {
    let status = message.status;
    let remainingTime = message.remaining_time;
    if (status === SessionState.RunningSingle) {
      this.remainingTime = remainingTime ? remainingTime : NaN;
      if (this.waitAllConnectedTriggerId) {
        clearInterval(this.waitAllConnectedTriggerId);
        this.waitAllConnectedTriggerId = null;
      }
      this.trackTriggerId ??= this.allConnectedHTTPTrigger(3500);
      if (!this.isSetUp) {
        this.setUpTaskHandler();
        this.isSetUp = true;
      }
      this.isLoading &&= false;
    }
    if (status === "wait") {
      this.remainingTime = remainingTime ? remainingTime : NaN;
      if (this.remainingTime < 0) {
        this.$router.push({
          name: "transitionSession",
          props: {
            sessionId: this.sessionId,
          },
        });
        return;
      }
    }
    if (status === "wait_group") {
      this.$router.push({
        name: "transitionSession",
        props: {
          sessionId: this.sessionId,
        },
      });
      return;
    }
    if (status === SessionState.RunningGroup) {
      this.$router.push({
        name: "groupSession",
        props: {
          sessionId: this.sessionId,
        },
      });
    }
  }
}
